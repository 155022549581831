.airdrop_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;

  .title {
    margin-bottom: 5%;
    font-size: 30px;
    font-weight: 700;
  }

  .subtitle {
    text-align: center;
    font-weight: 300;
    margin-bottom: 5%;
  }

  .connect_button {
    padding: 10px;
    background: #24A1DE;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%; 
    width: 100%; 
  }

  .wallet {
    width: 60px;
  }
    
  .arrow_airdrop {
    height: 20px;
  }
}

.content {
  width: 94%;
  margin: auto;

  .title {
    font-size: 17px;
    font-weight: 500;
  }
}

.max-width {
  width: 100%;
}

#ton-connect-button {
  z-index: 9999;
  position: absolute;
  button {
    background: transparent !important;
    color: transparent !important;
  }

  div {
    background: transparent !important;
    color: transparent !important;
  }
  
svg {
  display: none;
}

}

.image_container {
  width: 70%;
  z-index: 1;
  margin: 10%;
}


.go3974562317 {
  background-color: #0098EA;
  color: #FFFFFF;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  padding: 8px 16px 8px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 60px !important;
  width: 90vw !important;
}
