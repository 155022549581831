.upgrade1 {
    width: 100%;
    border-radius: 20px;
    background: #26292e;


    .profit_info{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profit1 {
        display: flex;
        align-items: center;
        border-bottom: 2px #605860 solid;
        padding: 5px 15px;
        gap: 10px;
        flex-direction: column;
        

        .icon {

            img {
                width: 100%;
                height: auto;
            }
        }

        .info2 {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .title {
                color: #FFF;
                font-size: 13px;
                font-weight: 500;
                text-align: center;
            }

            .profit_info {
                .text {
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 500;
                    opacity: 0.5;
                    margin-bottom: 2px;
                }
                .profit2 {
                    display: flex;
                    align-items: center;
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 700; 

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .info1 {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        gap: 10px;

        .separattor {
            background-color: #605860;
            width: 2px;
            height: 30px;
        }

        .info_block{
            font-size: 10px;
            font-weight: 500;
            color: #ffffff;
            opacity: 0.8;
            text-align: center;
            line-height: 1.4;
        }

        .lvl {
            color: #FFF;
            font-size: 15px;
            font-weight: 700;
            // margin-right: 10px;
            min-width: 31px;
        }

        .upgrade_cost {
            display: flex;
            align-items: center;
            color: #FFF;
            font-size: 15px;
            font-weight: 700; 

            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    @media (max-width: 375px) {
        .profit1 {
            gap: 15px;
            padding: 5px 10px;

           

            .info2 {
                gap: 10px;

                .title {
                    font-size: 12px;
                }

                .profit_info {
                    .text {
                        font-size: 10px;
                    }
                    .profit2 {
                        font-size: 12px;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .info1 {
            padding: 5px 10px;
            gap: 5px;

            .lvl {
                font-size: 13px;
            }

            .upgrade_cost {
                font-size: 13px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }

            .separattor {
                height: 20px;
            }
        }
    }
}
