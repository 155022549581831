.units {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;

    height: 100vh; 
    overflow-y: scroll; 
    padding-bottom: 20px; 

    .title_units {
        font-size: 30px;
        font-weight: 600;
        margin-top: 10px;
    }

    .selected {
        background: #444 !important;
    }

    .unit {
        display: flex;
        height: 70px;
        flex-direction: row;
        align-items: center;
        background: #272a2f;
        border-radius: 20px;
        justify-content: space-between;
        
        .icon {
            width: 80px;
        }
        
        .action_icon {
            width: 50px;
        }
        
        .info_container_units {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}
.units_scroll{
    width: 95%;
    margin: auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10vh;
}
.modal_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 90%;

    img{
        max-width: 80% ;
    }
    h3{
        margin: 5vh 0 ;
        text-align: center;
    }
}