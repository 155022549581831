.battle-bear-page {
    text-align: center;
    background-color: #000;
    color: #fff;
    font-family: 'Arial', sans-serif;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.bear-image {
    margin-bottom: 0px;
    position: relative;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
    }

    .text_container {
        position: absolute;
        bottom: 30%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bft-logo {
            width: 350px; 
            height: auto; 
            margin-bottom: 0.3rem; 
        }

        p {
            font-size: 1.5rem;
            margin-bottom: 0.2rem;
        }

        h2 {
            font-size: 4rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
    }
}

.content {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 100;

    .socials {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 1rem;
            margin-bottom: 30px;
        }

        .icons {
            display: flex;
            justify-content: center;

            a {
                margin: 0 15px;
                background: white;
                display: flex;
                align-items: center;
                padding: 5px;
                border-radius: 50%;

                img {
                    width: 30px;
                }
            }
        }
    }
}

.loader-line {
    width: 50%;
    height: 10px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    margin: 10px auto;
    border-radius: 5px;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 10px;
    width: 40%;
    background-color: coral;
    animation: lineAnim 3s linear infinite;
    border-radius: 5px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}
