.outer_container_thanks {
    height: 55vh;
    box-sizing: border-box;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    border-top: 3px solid rgb(236, 181, 40);
    box-shadow: 0px -5px 20px 0px rgba(236, 181, 40, 0.81);
    width: 100%;
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1c1f24;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    justify-content: space-evenly;
}


.binance_container{
    position: absolute;
    top: -30px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .binance_logo {
        width: 70px;
        height: 70px;
        background: #272a2f;
        padding: 10px;
        border-radius: 50%;
        border: 5px solid #1c1f24;
    }
}


.coin_display {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 5vh;

    img{
        width: 50px;
        margin-right: 10px;
    }
}

.coin_icon {
    font-size: 2.5rem;
    margin-right: 10px;
}

.coin_amount {
    font-size: 3rem;
    font-weight: bold;
    color: white;
}

.message {
    font-size: 1.4rem;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
}

.button_thanks {
    width: 90%;
    min-height: 70px;
    padding: 12px 8px;
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    background: #5a60fe;
    font-weight: 500;
    border-radius: 15px;
}

.content_thanks {
    display: flex;
    flex-direction: column;
    background: #272a2f;
    margin: 5%;
    position: relative;
    padding-bottom: 10%;
    border-radius: 15px;
}

