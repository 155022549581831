.mines_container {
    width: 100%;
    height: 120vh;

    .background {
        border-radius: 25px 25px 0px 0px;
        border-top: 3px solid #ECB528;
        background: #1d1e23;
        width: 100%;
        height: 100%;
        margin-top: 15px;
        box-shadow: 0px -10px 50px 0px rgba(236, 181, 40, 0.81);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 22px 0;
    

        .stat-block {
            margin: 0 !important;
            padding: 0 !important;
            display: flex;
            width: 100%;
            margin-bottom: 20px !important;
            max-width: 680px;
        }

        .upgrades_container {
            width: 100%;
            padding: 25px 0 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 14px;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 62vh;
            justify-content: center;

            @media screen and (max-height: 903px) {
                margin-bottom: 35vh;
                max-height: none;
            }

            @media screen and (max-height: 810px) {
                margin-bottom: 37vh;
                max-height: none;
            }
            @media screen and (max-height: 700px) {
                margin-bottom: 39vh;
                max-height: none;
            }
            @media screen and (max-height: 620px) {
                margin-bottom: 42vh;
                max-height: none;
            }
            @media screen and (max-height: 580px) {
                margin-bottom: 44vh;
                max-height: none;
            }
        }

        .upgrade_item {
            width: 48%;
            height: fit-content;

            @media screen and (max-width: 400px) {
                width: 100%;
            }
        }
    }
}

.profit_per_hour {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mine-modal-image {
    width: 110px;
    height: 110px;
}

.mine-modal-title {
    font-size: 24px;
    font-weight: 600; 
}

.mine-upgrade-description-text {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}

.profit_per_hour {
    p {
        font-size: 14px;
        font-weight: 500;
    }

    .row_boosts.one {
        margin: 0;
        align-items: center;

        img {
            width: 29px;
            height: 29px; 
        }

        span {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
        }
    }
}

.row_boosts.two {
    margin-top: 25px;
}

.category-switcher{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;


    .category-button{
        background: none;
        border: none;
        color: white;
        font-size: 15px;
        width: 25%;
        text-align: center;
        padding: 5px;
    }

    .active{
        background: #1b44ff;
        border-radius: 15px;
    }
}

.warning_mine{
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
}
