.skins_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);     
    gap: 10px;
    padding: 20px;
}
.skin{
    background: #26292e;
    border-radius: 15px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 20px;

    .img_icon{
        height: 80px;
    }

    .name{
        width: 100%;
        text-align: center;
        font-weight: 300;
        font-size: 15px;
    }
    .image_status{
        height: 20px;
        position: absolute;
        right: 5%;
        top: 10px;
    }
}
.selected_skin_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;

    .selected_skin {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img_main{
                width: 80vw;
        }


        .image_container{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    
   
    .navigation {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
        top: 50%;

        button:last-child img {
            transform: rotate(180deg);
        }

        button img {
            height: 30px;
        }

        button {
            background: none;
            border: none;
            outline: none;
        }
    }

    .selected_skin_infoBlock{
        background-color: #26292e;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        border-radius: 15px;
        width: 90%;

        .selected_skin_name{
            font-weight: 700;
            margin-top: 10px;
            font-size: 20px;
        }
        .selected_skin_description{
            font-size: 15px;
            font-weight: 300;
            padding: 0 20px;      // 10px Abstand links und rechts, 0px oben und unten
            text-align: center; 
        }
        .selected_skin_price{

        }
        .selected_skin_purchased{
            color: #7ffa8b;
        }

        .selected_skin_league{
            background: #373a6d;
            width: 90%;
            text-align: center;
            padding: 7px;
            margin-bottom: 20px;
            border-radius: 15px;
            font-size: 20px;
            font-weight: 600;
        }
    }
}
  
.skins_page{
    overflow-y: scroll;
    height: 100vh;
}
  
  
