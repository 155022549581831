.divider {
    height: 66%;
    width: 2px;
    margin: 0 6px;
    background: linear-gradient(#00D1FF, #8165FF, #FA00FF);
}
.menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: #272a2f;
    border-radius: 25px 25px 0 0;
    padding: 5px 12px;
    position: fixed;
    bottom: 0;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
    gap: 15px;
}
.menu-button img {
    height: 36px;
}
.menu-button {
    max-width: 70px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    height: 100%;
    border-radius: 10px;
}

@media screen and (max-width: 430px) {
   
}

@media screen and (max-width: 395px) {
    .menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #262429;
        border-radius: 25px;
        padding: 5px 12px;
        position: fixed;
        bottom: 0;
        gap: 0;
    }
    .menu-button img {
        height: 10vw;
    }
    .menu-button {
        padding: 10px 10px;
    }
}
