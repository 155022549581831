.telegram-game-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .google-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .return-button {
    position: fixed; /* Button immer an derselben Stelle */
    top: 10px; /* Abstand von oben */
    left: 10px; /* Abstand von links */
    padding: 5px;
    background-color: black; /* Schwarzer Hintergrund */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Immer im Vordergrund */

    &:hover {
      background-color: #333333; /* Dunkelgrauer Hintergrund bei Hover */
    }

    &:active {
      background-color: #1a1a1a; /* Noch dunklerer Hintergrund bei Aktivierung */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .return-button-icon {
      width: 30px; /* Bildbreite */
      height: 20px; /* Bildhöhe */
      display: block;
    }
  }

  .orientation-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100%;

    .orientation-icon {
      width: 110px; /* Bildbreite */
      height: 230px; /* Bildhöhe */
    }
  }
}
