$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_disabled {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-end;

    img{
        width: 145%;
    }

    .gradient-text {
        font-weight: 900;
        font-size: 5vh;
    }
}

