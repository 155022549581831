$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.modal {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-out;
  }
  
  .modal-container {
    width: 102%;
    border-radius: 30px 30px 0 0;
    position: absolute;
    bottom: -5px;
    border-top: 3px solid rgb(236, 181, 40);
    box-shadow: 0px -10px 50px 0px rgba(236, 181, 40, 0.81);
    background: #1c1e23;
}


.modal-content {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    flex-direction: column;
}

.modal-image {
    border-radius: 8px;
    text-align: center;
    width: 80px;
    height: auto;
}

.modal-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  padding: 30px 22px 20px;
  min-height: 55vh;
  justify-content: space-between;
}

.modal-title {
font-size: 22px;
}

.popup_text {
  margin-top: 15px;
  padding: 0 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 500;
  }
}

.modal-button {
margin-top: 16px;
}
.free_boosts_title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
}
.close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    width: 24px;
    height: 24px;
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}

.outer-container_boost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  
}

.container_glow {
  width: 100%;
  height: 100vh;
  padding: 8.3vw 5vw 0 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1b1e22;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
}

.button-long {
  background: rgb(90 96 255);
  color: white;
  padding: 28.5px 0;
  border-radius: 20px;
  font-size: 24px;
  margin-top: 10px;
  border: none;
  font-weight: 700;
}

.container_glow_modal{
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 5vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}

.boost_name{
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
}

.boost_img {
  max-width: 48px;
}
.shop-spcial-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    gap: 12px;
  }
  
  .shop-spcial-container > div {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 6px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    text-align: left;
    gap: 8px;
    z-index: 5;
}
.tab-section-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
}
.tab-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 480px;
    margin: 0 auto;
  }
  
  .tab-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    min-height: 64px;
    border-radius: 10px;
    padding-top: 16px;
}
  
  .tab-block-title {
    font-weight: bold;
    font-size: 20px;
    font-weight: 600;

  }

  .row {
    justify-content: start !important;
  }
  .tab-subblock {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 16px;
    background: #262a2e;
    padding: 12px 24px;
    border-radius: 20px;
  } 
  
  
  .rocket_boost{
    
    font-size: 100px;
    


}

.popup_upgrade_info {
  .row_boosts {
    width: fit-content;
    font-size: 24px;
    font-weight: 500;

    margin: 10px 0;

    span {
      font-size: 24px;
      font-weight: 700;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.row_boosts {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  width: 100%;
  font-weight: 500;
  gap: 4px;
  align-items: center;
  font-size: 16px;

  svg {
    margin: 0 5px;
  }

  span {
    font-size: 18px;
    font-weight: 700;
  }
}

.img_boost_container {
  min-width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_boost{
    height: 56px;
    // border-radius: 8px;
    // font-size: 2.1rem;
}
.daily_boost {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 10px;
  padding: 12px 24px;
  background: #262a2e;
  border-radius: 20px;
  justify-content: start;
  gap: 16px;
}
.daily_boosts_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.boost_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
}
.additional_boosts{
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
}
.header_background_boosts {
  position: absolute;
  width: 420px;
  height: 200px;
  background: radial-gradient(circle at center, #2cd09529, rgba(118, 58, 215, 0) 66%);
  overflow: visible;
  z-index: -1;
  top: 20%;
}

.scrollable {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 20vh;
}
.disabled{
  background: #535455;
}